<template>
  <app-list-view
    server-side
    app="sale"
    model="quotationheader"
    api-url="sale/quotation-header/"
    :title="$t('menu.quotationHeader')"
    :createTo="{ name: 'quotationHeaderCreate' }"
    :editTo="{ name: 'quotationHeaderEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'contact',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.quotation_number'),
          value: 'quotation_number'
        },
        { text: this.$t('fields.customerCompany'), value: 'contact_id.name' },
        {
          text: this.$t('fields.issueDate'),
          value: 'issue_date',
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
